<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item>视频列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <router-link to="/dashboard/video/add">
                    <el-button type="primary" icon="plus" size="small">添加视频</el-button>
                </router-link>
            </div>
        </div>
        <div class="content-main">
          
            <div class="filter-box">
                <el-form :inline="true" :model="filterForm" class="form-inline">
                    <el-form-item label="视频名：">
                        <el-input size="small" v-model="filterForm.name" placeholder="视频名"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="onSubmitFilter">查询</el-button>
                        <el-button size="small" @click="clear">清空</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="form-table-box">
                <el-table :data="tableData" style="width: 100%" stripe>
                    <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
                    <el-table-column prop="video_pic_url" label="视频缩略图" width="180">
                        <template scope="scope">
                            <img :src="scope.row.video_pic_url" alt="" style="width: 60px;height: 60px">
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="视频名称"></el-table-column>

                    <el-table-column prop="video_playtimes" label="播放次数" width="140" sortable>
                        <!-- <template scope="scope">
                            <el-input-number class="sort-width" size="mini" :min="1" :max="100" controls-position="right" v-model="scope.row.sort_order" placeholder="排序" @blur="submitSort(scope.$index, scope.row)" @change="submitSort(scope.$index, scope.row)"></el-input-number>
                        </template> -->
                    </el-table-column>

                    <el-table-column prop="sort_order" label="展示排序" width="140" sortable>
                        <template scope="scope">
                            <el-input-number class="sort-width" size="mini" :min="1" :max="100" controls-position="right" v-model="scope.row.sort_order" placeholder="排序" @blur="submitSort(scope.$index, scope.row)" @change="submitSort(scope.$index, scope.row)"></el-input-number>
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="显示" width="80">
                        <template scope="scope">
                            <el-switch
                                    v-model="scope.row.is_show"
                                    active-text=""
                                    inactive-text=""
                                    active-value="1" 
                                    inactive-value="0"
                                    @change='changeStatus($event,scope.row._id)'>
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作">
                        <template scope="scope">
                            <el-button size="small" @click="handleRowEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" plain type="danger" @click="handleRowDelete(scope.$index, scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page-box">
                <el-pagination @current-change="handlePageChange" :current-page="page" :page-size="size"
                               layout="total, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                page: 0,
				size:10,
                total: 0,
                filterForm: {
                    name: ''
                },
                tableData: [],
                activeName: 'second',
                pIndex: 0,
                num: 0,
                activeClass: 0,
            }
        },
        methods: {
            test(){
                console.log(this.tableData);
            },
            
            handleClick(tab, event) {
                let pindex = tab._data.index;
				this.page = 1;
                this.activeClass = 0;
                if (pindex == 0) {
                    this.getList();
                    this.pIndex = 0;
                }
                else if (pindex == 1) {
                    this.getVideoList();
                    this.pIndex = 1;
                }
                else if (pindex == 2) {
                    this.getOutList();
                    this.pIndex = 2;
                }
                else if (pindex == 3) {
                    this.getDropList();
                    this.pIndex = 3;
                }
            },
            handlePageChange(val) {
                this.page = val;
                let nIndex = this.pIndex;
                if (nIndex == 0) {
                    this.getList();
                }
            },
            handleRowEdit(index, row) {
                var id = row._id;

                var ritem = null;
                for(var i = 0;i!=this.tableData.length;i++)
                {
                    if(this.tableData[i]._id==id)
                    {
                        ritem = this.tableData[i];
                    }
                }
                if(!!ritem)
                {
                    this.$router.push({name: 'video_add', query: {id: row._id,videoInfo:ritem}});
                }
            },
            changeStatus($event, para) {
                var that = this;
                this.axios.post('video/updateShow', {
                    params: {
                        id: para,
                        is_show:$event
                    }
                }).then((response) => {
                    if(response.data.code>0)
                    {
                        that.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                    }
                })
            },
            handleRowDelete(index, row) {

                this.$confirm('确定要删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                let that = this;
                console.log("row._id is " + row._id);
                that.axios.post('video/deleteVideo', {id: row._id}).then((response) => {
                    if (response.data.code > 0) {
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.getVideoList();
                    }
                })
                }).catch(() => {
//                    this.$message({
//                        type: 'info',
//                        message: '已取消删除'
//                    });
                });
            },
            onSubmitFilter() {
                this.page = 1;
                this.getList();
            },
            clear(){
                this.axios.get('video/getVideoList', {
                    params: {
                        page: this.page,
						size: this.size
                    }
                }).then((response) => {
                    this.tableData = response.data.data
                    // this.page = response.data.data.currentPage
                    // this.total = response.data.data.count
                })
            },
            submitSort(index, row){
                this.axios.post('video/updateSort', { id: row.id,sort_order:row.sort_order }).then((response) => {

                })
            },
            getVideoList() {
                this.axios.get('video/getVideoList', {
                    params: {
                        page: this.page,
						size: this.size
                    }
                }).then((response) => {

                    console.log("获取视频列表。。。");
                    console.log(response.data);
                    this.tableData = response.data.data
                    //  this.page = response.data.data.currentPage
                    // this.total = response.data.data.count
                })
            },
            sortOrder(num) {
                this.num = num;
                this.pIndex = 4;
                this.activeClass = num;
                this.axios.get('video/sort', {
                    params: {
                        page: this.page,
						size: this.size,
                        index: num
                    }
                }).then((response) => {
                    this.tableData = response.data.data.data;
                    this.page = response.data.data.currentPage;
                    this.total = response.data.data.count;
                })
            }
        },
        components: {},
        mounted() {
            this.getVideoList();
        }
    }

</script>

<style scoped>
    .sort-width{
        width: 90px;
    }
    .right{
        float: right;
    }
    .form-inline {
        margin-top: 2px;
        height: 40px;
        margin-right: 20px;
    }

    .block {
        margin-bottom: 10px;
        height:42px;
        display: flex;
        align-items: center;
        justify-content:space-between;
    }

    .active {
        border-color: #ff4949;
        color: #ff4949;
    }

    .marginRight{
        margin-right: 20px;
    }
</style>
